<template>
  <div class="px-6">
    <h1 class="mb-12 text-9xl font-black text-gray-700 tracking-widest leading-none">404</h1>
    <h2 class="mb-4 font-bold text-2xl text-gray-700">Page not found</h2>
    <p class="mb-16 text-gray-700 max-w-md">
      Sorry, but we can't find the page you were looking for. It may have been moved or deleted, or the URL might be
      wrong.
    </p>

    <RouterLink to="/" class="btn btn-green mb-8">Go to homepage</RouterLink>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Page Not Found | Lucky Won",
  },
};
</script>
